<template>
  <section class="ant-layout site-layout">
    <header class="ant-layout-header site-header">
      <div class="flex-between i-back-normal-head">
        <div class="i-back-head-left">
          <span class="i-back-normal-head-text">账号</span>
        </div>
        <div @click="openExchangeCodeModal" class="i-back-normal-head-right-text">
          兑换会员
        </div>
      </div>
    </header>
    <main class="ant-layout-content main-content">
      <div class="account flex-column">
        <div class="account-head flex-between i-card">
          <div class="account-head-left flex-row">
            <div @click="showUserEditModal = true" class="account-head-avatar-box flex-center i-cursor">
              <img v-if="!!user.avatar" class="account-head-avatar" :src="user.avatar"/>
              <div class="account-head-avatar-mask">编辑</div>
            </div>
            <div class="account-profile">
              <div class="account-name ellipsis">{{ user.nickname }}</div>
              <div class="account-member flex-row">
                <span class="account-member-item">UID：{{ user.uid }}</span>
                <span class="account-member-item">{{user.loginMethodText}}</span>
              </div>
            </div>
          </div>
          <div v-if="user.rid == 2" class="account-head-right flex-row">
            <div style="text-align: right">
              <button type="button" class="ant-btn i-button">
                <span>{{user.rolename}} 有效期至{{user.memberExpireTimeText}}</span>
              </button>
              <button @click="openMemberProduct" type="button" class="ant-btn i-button button-yellow">
                <span>购买会员</span>
              </button>
            </div>
          </div>
          <div v-else class="account-head-right flex-row">
            <span style="margin-right: 16px;display: inline-block;max-width: 400px;word-break: break-all;color: rgb(135, 141, 147);">升级VIP会员可享用财咖云插件卓越计算能力</span>
            <div style="text-align: right">
              <button @click="openMemberProduct" type="button" class="ant-btn i-button button-yellow">
                <span>成为会员</span>
              </button>
            </div>
          </div>
        </div>
        <div class="account-binds i-special-card">
          <div class="flex-between account-binds-item">
            <span class="flex-row">
              <span class="account-binds-item-name-box">手机</span>
              <span v-if="user.isBindPhone" class="account-binds-item-img-box flex-row">
                <img src="../../assets/static/icon_binded.c4c1e8d8.svg" />已绑定
              </span>
              <span v-else class="account-binds-item-img-box flex-row">
                <img src="../../assets/static/icon_unbind.5cf983a4.svg" />未绑定
              </span>
            </span>
            <span class="account-binds-item-phone-intro">绑定后，即可用该手机号登录财咖软件App、小程序等产品</span>
            <button v-if="!user.isBindPhone" @click="openBindPhoneModal" type="button" class="ant-btn i-button button-red account-binds-item-button">
              <span>绑 定</span>
            </button>
            <button v-else @click="openChangePhoneModal" type="button" class="ant-btn i-button button-red account-binds-item-button">
              <span>换 绑</span>
            </button>
          </div>
          <div class="flex-between account-binds-item">
            <span class="flex-row account-binds-item-name-box">
              <span class="account-binds-item-name-box">密码</span>
              <span v-if="user.isSetPassword" class="account-binds-item-img-box flex-row">
                <img src="../../assets/static/icon_binded.c4c1e8d8.svg" />已设置
              </span>
              <span v-else class="account-binds-item-img-box flex-row">
                <img src="../../assets/static/icon_unbind.5cf983a4.svg" />未设置
              </span>
            </span>
            <button @click="openPasswordEditModal" type="button" class="ant-btn i-button button-red account-binds-item-button">
              <span>设置密码</span>
            </button>
          </div>
          <div class="flex-between account-binds-item">
            <span class="flex-row">
              <span class="account-binds-item-name-box">微信</span>
              <span v-if="user.isBindWechat" class="account-binds-item-img-box flex-row">
                <img src="../../assets/static/icon_binded.c4c1e8d8.svg" />已绑定
              </span>
              <span v-else class="account-binds-item-img-box flex-row">
                <img src="../../assets/static/icon_unbind.5cf983a4.svg" />未绑定
              </span>
            </span>
            <div style="flex-shrink: 0">
              <button v-if="!user.isBindWechat" @click="getWechatQrcodeUrl" type="button" class="ant-btn i-button button-red account-binds-item-button">
                <span>绑 定</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </main>

    <CustomModal v-model="showRightModal" :width="728" :nopadding="true">
      <div class="ant-tabs ant-tabs-top products-tab">
        <div class="ant-tabs-nav">
          <div class="ant-tabs-nav-wrap">
            <div class="ant-tabs-nav-list" style="transform: translate(0px, 0px)">
              <div class="ant-tabs-tab ant-tabs-tab-active">
                <div class="ant-tabs-tab-btn">个人会员</div>
              </div>
            </div>
          </div>
        </div>
        <div class="ant-tabs-content-holder">
          <div class="ant-tabs-content ant-tabs-content-top">
            <div class="ant-tabs-tabpane ant-tabs-tabpane-active">
              <div class="flex-between">
                <div class="products-content">
                  <div class="products-content-head flex-between">
                    <div>会员权利在PC端、移动端等产品均可使用</div>
                  </div>
                  <div class="products-items">
                    <div
                      v-for="(product, index) in productList"
                      :key="product.id"
                      @click="switchProduct(product, index)"
                      :class="{'product-item-personal-active':productIndex == index,}"
                      class="product-item-normal">
                      <div class="product-item-member-type flex-between">
                        <div class="ellipsis">
                          <span>{{ product.name }}</span>
                        </div>
                        <div v-if="!!product.offerDesc" class="product-item-recommend ellipsis" style="background: rgb(248, 77, 77)">
                          <span>{{ product.offerDesc }}</span>
                        </div>
                      </div>
                      <div class="product-item-price">
                        <span class="product-item-price-unit">￥</span>
                        <span class="product-item-price-number">{{product.price}}</span>
                        <span class="product-item-price-period">/{{ product.monthCount }}个月</span>
                      </div>
                      <div class="product-item-bottom">
                        <div class="product-item-count">￥{{ product.monthPrice }}/月</div>
                        <div class="product-item-intro ellipsis">
                          <span>{{ product.remark }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="product-options flex-between">
                    <div v-if="showVoucherCode == false"
                      class="product-options-item product-options-personal-item flex-row">
                      <span>使用优惠</span>
                      <button
                        @click="showVoucherCode = true"
                        type="button"
                        class="ant-btn i-button button-white"
                        style="color: rgb(122, 128, 135);min-width: 96px;height: 32px;margin: 0px 8px;">
                        <span>优惠码</span>
                      </button>
                    </div>
                    <div v-else class="product-options-item product-options-personal-item flex-row">
                      <input
                        v-model="voucherCode"
                        class="ant-input i-input"
                        placeholder="优惠码"
                        type="text"
                        style="width: 130px;height: 32px;margin-right: 8px;"
                        autocomplete="off"/>
                      <button
                        @click="validVoucherCode"
                        type="button"
                        class="ant-btn i-button button-red"
                        style="min-width: 64px; height: 32px">
                        <span>验 证</span>
                      </button>
                      <div v-if="deratePrice > 0" style="margin-left: 5px;color: #f84d4d;font-size: 14px;">已优惠{{deratePrice}}元</div>
                    </div>
                  </div>
                  <div class="product-pay flex-between">
                    <div class="product-pay-left flex-row">
                      <div class="product-pay-code" style="height: 112px; width: 112px">
                        <img v-if="!!qrcode" :src="qrcode" style="height: 112px; width: 112px"/>
                      </div>
                      <div class="product-pay-number">
                        <div class="product-pay-should">
                          <span class="product-pay-should-text">应付</span>
                          <span class="money" style="display: inline-block">
                            <span>￥</span>
                            <span class="yuan">{{actualPrice}}</span>
                          </span>
                        </div>
                        <div class="pay-method-tip flex-row">
                          <img src="../../assets/static/wechatpay.5c2dc7dc.svg"/>使用微信扫码付款
                        </div>
                      </div>
                    </div>
                    <div class="product-pay-right">
                      <div class="product-pay-right-box flex-center">
                        会员有效期：<span class="product-item-bottom-pay-intro">{{product.expireTime}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="product-bottom">
                    支付即视为你同意<router-link tag="a" target="_blank" :to="{path:'/userAgreement'}">《用户协议》</router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CustomModal>

    <CustomModal v-model="showUserEditModal" title="修改信息">
      <div class="change-info-modal">
        <div class="flex-between change-info-image">
          <div class="change-info-left">上传头像</div>
          <div class="change-info-right">
            <img v-if="!!editUser.avatar" class="change-info-right-img" :src="editUser.avatar"/>
            <span class="change-info-right-mask flex-center">
              <div class="ant-upload ant-upload-select ant-upload-select-text">
                <span class="ant-upload" style="position: relative">
                  <input
                    @change="updateImage($event)"
                    type="file"
                    accept="image/png,image/gif,image/jpeg"
                    style="position: relative;opacity: 0;width: 60px;z-index: 99;"/>
                  <span class="change-info-right-mask-text" style="position: absolute; left: 15px; top: 0;">选择</span>
                </span>
              </div>
            </span>
          </div>
        </div>
        <div class="change-info-name">
          <input v-model="editUser.nickname" class="ant-input i-input" maxlength="20" type="text"/>
        </div>
        <div class="change-info-submit">
          <button @click="showUserEditModal = false" type="button" class="ant-btn i-button button-grey left-button">
            <span>取 消</span>
          </button>
          <button @click="editUserInfo" type="button" class="ant-btn i-button button-red right-button">
            <span>保 存</span>
          </button>
        </div>
      </div>
    </CustomModal>

    <CustomModal v-model="showBindPhoneModal" title="绑定手机">
      <div>
          <div class="ant-row ant-form-item">
            <div class="ant-col ant-form-item-control">
              <div class="ant-form-item-control-input">
                <div class="ant-form-item-control-input-content">
                  <div class="account-input">
                    <input v-model="editUser.phone" class="ant-input i-input islide-input" placeholder="手机号" type="text"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="ant-row ant-form-item" style="margin-bottom: 80px">
            <div class="ant-col ant-form-item-control">
              <div class="ant-form-item-control-input">
                <div class="ant-form-item-control-input-content">
                  <div style="position: relative">
                    <input v-model="editUser.smscode" class="ant-input i-input" placeholder="验证码" maxlength="10" type="text"/>
                    <button
                      @click="sendSmsCode"
                      type="button"
                      class="ant-btn ant-btn-link"
                      style="position: absolute;right: 0px;top: 0px;height: 44px;"
                      :disabled="downCount > 0">
                      <span v-if="downCount > 0">{{ downCount }}s 后重发</span>
                      <span v-else>获取验证码</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="ant-row ant-form-item" style="margin-bottom: 0px">
            <div class="ant-col ant-form-item-control">
              <div class="ant-form-item-control-input">
                <div class="ant-form-item-control-input-content">
                  <button @click="bindPhone" class="ant-btn i-button button-red only-button">
                    <span>绑 定</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
      </div>
    </CustomModal>

    <CustomModal v-model="showPasswordEditModal" title="设置密码">
      <div>
          <div class="ant-row ant-form-item">
            <div class="ant-col ant-form-item-control">
              <div class="ant-form-item-control-input">
                <div class="ant-form-item-control-input-content">
                  <div class="account-input">
                    <input v-model="editUser.phone" class="ant-input i-input islide-input" placeholder="手机号" type="text" disabled/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="ant-row ant-form-item">
            <div class="ant-col ant-form-item-control">
              <div class="ant-form-item-control-input">
                <div class="ant-form-item-control-input-content">
                  <div style="position: relative">
                    <input
                      v-model="editUser.smscode"
                      class="ant-input i-input"
                      placeholder="验证码"
                      maxlength="10"
                      type="text"/>
                    <button
                      @click="sendSmsCode"
                      type="button"
                      class="ant-btn ant-btn-link"
                      style="position: absolute;right: 0px;top: 0px;height: 44px;"
                      :disabled="downCount > 0">
                      <span v-if="downCount > 0">{{ downCount }}s 后重发</span>
                      <span v-else>获取验证码</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="ant-row ant-form-item">
            <div class="ant-col ant-form-item-control">
              <div class="ant-form-item-control-input">
                <div class="ant-form-item-control-input-content">
                  <div class="account-input">
                    <input
                      v-model="editUser.newpassword"
                      class="ant-input i-input islide-input"
                      placeholder="新密码"
                      type="password"
                      autocomplete="new-password"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="ant-row ant-form-item">
            <div class="ant-col ant-form-item-control">
              <div class="ant-form-item-control-input">
                <div class="ant-form-item-control-input-content">
                  <div class="account-input">
                    <input
                      v-model="editUser.repassword"
                      class="ant-input i-input islide-input"
                      placeholder="确认密码"
                      type="password"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="ant-row ant-form-item" style="margin-bottom: 0px">
            <div class="ant-col ant-form-item-control">
              <div class="ant-form-item-control-input">
                <div class="ant-form-item-control-input-content">
                  <button @click="editUserPassword" class="ant-btn i-button button-red only-button">
                    <span>提交</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
      </div>
    </CustomModal>

    <CustomModal v-model="showExchangeCodeModal" title="兑换会员">
      <div class="code-modal-intro">
        <div class="code-modal-head">如何获取兑换码</div>
        <div class="code-modal-text">我们会不定期举行一些运营活动，届时会有兑换码可领取</div>
      </div>
      <div class="ant-row ant-form-item" style="margin-bottom: 76px">
        <div class="ant-col ant-form-item-control">
          <div class="ant-form-item-control-input">
            <div class="ant-form-item-control-input-content">
              <input
                v-model="exchangeCode"
                class="ant-input i-input"
                placeholder="兑换码"
                type="text"/>
            </div>
          </div>
        </div>
      </div>
      <div class="ant-row ant-form-item" style="margin-bottom: 0px">
        <div class="ant-col ant-form-item-control">
          <div class="ant-form-item-control-input">
            <div class="ant-form-item-control-input-content">
              <button @click="showExchangeCodeModal = false" type="button" class="ant-btn i-button button-grey left-button">
                <span>取 消</span>
              </button>
              <button @click="exchangeCodeMember" class="ant-btn i-button button-red member-time-list-box-bottom-button right-button">
                <span>确认兑换</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </CustomModal>
    
    <CustomModal v-model="showChangePhoneModal" title="换绑手机">
        <div>
            <div class="ant-row ant-form-item">
              <div class="ant-col ant-form-item-control">
                <div class="ant-form-item-control-input">
                  <div class="ant-form-item-control-input-content">
                    <div class="account-input">
                      <input
                        v-model="editUser.phone"
                        class="ant-input i-input islide-input"
                        placeholder="手机号"
                        type="text"
                        :disabled = "!isValidPhone"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="ant-row ant-form-item" style="margin-bottom: 80px">
              <div class="ant-col ant-form-item-control">
                <div class="ant-form-item-control-input">
                  <div class="ant-form-item-control-input-content">
                    <div style="position: relative">
                      <input
                        v-model="editUser.smscode"
                        class="ant-input i-input"
                        placeholder="验证码"
                        maxlength="10"
                        type="text"/>
                      <button
                        @click="sendSmsCode"
                        type="button"
                        class="ant-btn ant-btn-link"
                        style="position: absolute;right: 0px;top: 0px;height: 44px;"
                        :disabled="downCount > 0">
                        <span v-if="downCount > 0">{{ downCount }}s 后重发</span>
                        <span v-else>获取验证码</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="ant-row ant-form-item" style="margin-bottom: 0px">
              <div class="ant-col ant-form-item-control">
                <div class="ant-form-item-control-input">
                  <div class="ant-form-item-control-input-content">
                    <button @click="changePhone" class="ant-btn i-button button-red only-button">
                      <span>{{!isValidPhone ? '验证' : '换绑'}}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </CustomModal>

  </section>
</template>

<script>
import {replaceState} from '@/utils';

export default {
  name: "account",
  data() {
    return {
      user: {},
      productList: [],
      showRightModal: false,
      productIndex: 0,
      product: {},
      showUserEditModal: false,
      editUser: {
        avatar: null,
        nickname: "",
        phone: "",
        smscode: "",
        newpassword: "",
        repassword: "",
        smstype: 5
      },
      showBindPhoneModal: false,
      downCount: 0,
      showPasswordEditModal: false,
      showExchangeCodeModal: false,
      exchangeCode: "",
      voucherCode: '',
      showVoucherCode: false,
      voucherPrice: 0,
      useVoucherCode: '',
      showChangePhoneModal: false,
      isValidPhone: false,
      qrcode: '',
      orderNo: '',
      actualPrice: '',
      deratePrice: ''
    };
  },
  created() {
    if(this.$route.query.token){
        replaceState();
    }
    let msg = this.$route.query.msg;
    if(msg){
       this.$Message.error(msg);
    }
    this.getUserInfo();
  },
  methods: {
    openMemberProduct() {
      this.voucherCode = '';
      this.showVoucherCode = false;
      this.useVoucherCode = '';
      this.qrcode = '';
      this.showRightModal = true;
      this.getProductList();
    },
    getProductList() {
      this.$http.get("/api/user/getRightProductList").then((data) => {
        this.productList = data;
        let product = data[this.productIndex];
        if(!product) {
          if(data.length > 0) {
            this.productIndex = 0;
            product = data[0];
          }
        }
        this.product = product || {};
        if(product){
           this.createWechatPayQrcode();
        }
      });
    },
    getUserInfo() {
      this.$http.get("/api/user/getUserInfo").then((data) => {
        this.user = data;
        this.editUser.avatar = data.avatar;
        this.editUser.nickname = data.nickname;
        this.editUser.phone = data.username;
      });
    },
    switchProduct(product, index) {
      if(product.id == this.product.id){
         return ;
      }
      this.product = product;
      this.productIndex = index;
      this.useVoucherCode = '';
      this.voucherCode = '';
      this.showVoucherCode = false;
      this.createWechatPayQrcode();
    },
    createWechatPayQrcode(){
      this.$http.post("/api/user/createWechatPayQrcode",{sn: this.product.sn,voucherCode: this.useVoucherCode}).then((data) => {
         this.qrcode = data.path;
         this.orderNo = data.orderNo;
         this.actualPrice = data.actualPrice;
         this.deratePrice = data.deratePrice;
         this.checkOrderStatus();
      });
    },
    checkOrderStatus(){
      if(this.showRightModal == false){
         return ;
      }
      this.$http.post("/api/user/checkOrderStatus",{orderNo: this.orderNo},{loading: false}).then((data) => {
         if(!data || data.orderStatus == 15){
           clearTimeout(this.orderstatustimeout);
           this.orderstatustimeout = setTimeout(() => {
              this.checkOrderStatus();
           }, 1000);
         }else {
           this.getUserInfo();
           this.$Message.success("付款成功");
           this.showRightModal = false;
         }
      });
    },
    editUserInfo() {
      this.$http.get("/api/user/upateUserInfo", {
          avatar: this.editUser.avatar,
          nickname: this.editUser.nickname,
        }).then(() => {
          this.$Message.success("成功");
          this.getUserInfo();
          this.showUserEditModal = false;
        });
    },
    updateImage(event) {
      let files = event.target.files;
      if (!files || files.length == 0) {
        this.$Message.error("请选择上传文件");
        return;
      }
      let formData = new FormData();
      formData.append("file", files[0]);
      this.$http.uploadFile("/api/admin/uploadFile", formData).then((data) => {
        this.editUser.avatar = data;
      });
    },
    sendSmsCode() {
      this.$http
        .post("/api/sendSmsCode", { phone: this.editUser.phone,smstype: this.editUser.smstype})
        .then(() => {
          this.$Message.success("验证码发送成功");
          clearTimeout(this.downCountTimeOut);
          this.downCount = 60;
          this.startDownCount();
        });
    },
    startDownCount() {
      this.downCountTimeOut = setTimeout(() => {
        if (this.downCount > 0) {
          this.downCount--;
          this.startDownCount();
        }
      }, 1000);
    },
    openBindPhoneModal(){
      this.resetSms(7);
      this.showBindPhoneModal = true;
    },
    bindPhone() {
      this.$http.post("/api/user/bindPhone", this.editUser).then(() => {
        this.$Message.success("绑定成功");
        this.getUserInfo();
        this.showBindPhoneModal = false;
      });
    },
    openPasswordEditModal(){
      this.resetSms(5);
      this.editUser.phone = this.user.username;
      this.showPasswordEditModal = true;
    },
    editUserPassword() {
      this.$http.post("/api/user/editUserPassword", this.editUser).then(() => {
        this.$Message.success("设置成功");
        this.getUserInfo();
        this.showPasswordEditModal = false;
      });
    },
    openExchangeCodeModal(){
       this.exchangeCode = '';
       this.showExchangeCodeModal = true;
    },
    exchangeCodeMember() {
      this.$http
        .post("/api/user/exchangeCodeMember", {
          exchangeCode: this.exchangeCode,
        })
        .then(() => {
          this.$Message.success("兑换成功");
          this.getUserInfo();
          this.showExchangeCodeModal = false;
        });
    },
    validVoucherCode(){
       this.$http
        .post("/api/user/validVoucherCode", {
          voucherCode: this.voucherCode,
          productId: this.product.id || 0
        })
        .then(() => {
          this.$Message.success("验证成功");
          this.useVoucherCode = this.voucherCode;
          this.createWechatPayQrcode();
        });
    },
    getWechatQrcodeUrl(){
        this.$http.get('/api/common/getWechatQrcodeUrl',{state: 'bindWechat' + this.user.uid}).then(data=>{
          location.href = data;
        });
    },
    openChangePhoneModal(){
        this.resetSms(8);
        this.editUser.phone = this.user.username;
        this.isValidPhone = false;
        this.showChangePhoneModal = true;
    },
    changePhone(){
        this.$http.post("/api/user/changeBindPhone", {phone: this.editUser.phone,smscode: this.editUser.smscode,smstype: this.editUser.smstype,isValidPhone: this.isValidPhone}).then(() => {
           if(this.isValidPhone){
              this.$Message.success("换绑成功");
              this.getUserInfo();
              this.showChangePhoneModal = false;
           }else {
              this.resetSms(9);
              this.isValidPhone = true;
           }
        });
    },
    resetSms(smstype){
      this.editUser.phone = '';
      this.editUser.smscode = '';
      this.downCount = 0;
      this.editUser.smstype = smstype || 5;
    }
  }
}
</script>
<style scoped>
.account {
  width: 100%;
  height: 100%;
  background: #f7f9fc;
}
</style>